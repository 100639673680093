import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import ContactForm from "./contactForm"

const ContactBlock = () => (
  <Wrapper>
    <Container>
      <Row justify="center">
        <Col md={9} lg={8} xl={7}>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const Wrapper = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
`

const Title = styled.div`
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default ContactBlock
