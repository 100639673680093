import React from "react"
import HeroHeader from "../components/heroHeader"
import Layout from "../components/layout"
import ContactBlock from "../components/contactBlock"
import { graphql } from "gatsby"
import { getContactPath } from "../components/pathUtils"
import { HelmetDatoCms } from "gatsby-source-datocms"

const ContactPage = ({ data: { page, site }, pageContext, location }) => {
  const locale = pageContext.locale
  const i18nPaths = site.locales
    .filter(locale => locale !== "fr")
    .map(locale => {
      return {
        locale: locale,
        value: getContactPath(locale),
      }
    })

  return (
    <Layout
      lang={locale}
      title={page.title}
      location={location}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags}>
        <html lang={locale} />
      </HelmetDatoCms>
      <HeroHeader title={page.title} heroImage={page.heroImage} />
      <ContactBlock />
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ContactPageQuery($slug: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsContactPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      title
      id
      locale
      heroImage {
        fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
